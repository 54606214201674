/* You can add global styles to this file, and also import other style files */
@import './theme.scss';

html, body { 
  height: 100%; 
}

body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
}

.mainContentBody {
    height:100%;
    padding:0px !important;
}

.mainContentBodyContent {
    padding:1%;
    display: initial !important;
}

.mat-card-actions {
    margin-left: 0 !important; 
    margin-right: 0 !important;
}

h2 {
    @extend .mat-h2;
}

b {
    @extend .mat-body-strong;
}


td.mat-cell, td.mat-footer-cell, th.mat-header-cell {
  padding-right: 5px !important;
  padding-left: 2px !important;
}

// Workaround for https://github.com/angular/material2/issues/4580.
@mixin mat-form-field-multiline-workaround($typography-config: null) {
    @if $typography-config == null {
      $typography-config: mat-typography-config();
    }
  
    .mat-form-field {
      // Copy-paste from @mat-form-field-typography();
      $line-height: mat-line-height($typography-config, input);      
      $subscript-font-scale: 0.75;
  
      & & {
        &-underline {
          position: static;
        }
  
        &-subscript-wrapper {
          $subscript-margin-top: 0.5em / $subscript-font-scale;
          $subscript-min-height: $line-height * 1em;
          $subscript-margin-bottom: -#{$subscript-margin-top + $line-height};
  
          position: static;
          min-height: $subscript-min-height;
          margin-bottom: $subscript-margin-bottom;
        }
      }
  
      &-appearance-legacy &-subscript-wrapper {
          // Copy-paste from @mat-form-field-legacy-typography();
          $line-spacing: ($line-height - 1) / 2;
          $subscript-margin-top: 0.5em / $subscript-font-scale - ($line-spacing * 2);
  
          $subscript-min-height: $line-height * 1em;
          $subscript-margin-bottom: -#{$subscript-margin-top + $line-height};
  
          min-height: $subscript-min-height;
          margin-bottom: $subscript-margin-bottom;
      }
    }
  }
  
  @include mat-form-field-multiline-workaround();

  .responsive-mat-form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
  }

  .arc-container text {
    font-size: 14pt;
    font-weight: bold;
  }



  // code snippets
  // .mat-card-code {
  //   height: 200px !important;
  //   position: relative;
  //   overflow: hidden;
  // }
  
  // .mat-card-content-code {
  //   max-height: 100%;
  //   max-width: 100%;
  //   position: absolute;
  //   overflow: auto;
  // }

  .cdk-virtual-scroll-data-source-example .example-viewport {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
    height: 400px;
  }
  
  #chartdiv {
    width: 100%;
    height: 350px;
  }

  .div-form{
    min-width: 50%;
  }
  
  .mat-form-field-width { 
    width: 100% !important;
    display: inline-block !important;
    padding-left: 10px;
    textarea{
      min-width: 100% !important;
    }
    .mat-form-field-infix {
      min-width: 100% !important;
    }

  }

